import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element"];

  removeElement(e) {
    e.preventDefault();
    this.elementTarget.classList.add("tw-hidden");
  }
}
