import { Channel } from "@anycable/web"
import { EventCurrencyChanged,  EventPriceChanged,  EventPricePercentageChanged } from "../events";
import { getActiveCurrency } from "../util/currency";

export default class PwChannel extends Channel {
  static identifier = 'PwChannel'

  static setupChannel(cable) {
    // Retrieve list of coin ids that need live updating, signalled by data-coin-id with price target.
    const targets = [...document.querySelectorAll("[data-target='price.price'][data-coin-id], [data-price-target='price'][data-coin-id]")];
    const coinIds = new Set(targets.map(node => Number(node.getAttribute("data-coin-id"))));

    const channel = new PwChannel();
    cable.subscribe(channel);

    // Negotiate coins and active currency with the WS server.
    channel.setCoins(Array.from(coinIds));
    window.addEventListener(EventCurrencyChanged, e => channel.setCurrency(e.detail.currencyCode));

    // Handle reconnection so that clients will receive messages between deploys to cable server.
    channel.on('connect', (event) => {
      if (event.reconnect || event.restored) {
        channel.setCoins(Array.from(coinIds));
        channel.setCurrency(getActiveCurrency());
      }
    });
  }

  receive(message) {
    // Server responds with an acknowledgement when the currency is successfully changed.
    if (message.curr) {
      this.currency = message.curr;
    }

    // We may receive messages for the wrong currency due to network delays when changing
    // currencies. To prevent this, we can ignore these messages until the server responds
    // with an acknowledgement of the active currency.
    if (message.c && this.currency) {
      const coinId = message.c;
      const priceBTC = message.p;
      const priceChangePercentage24H = { [this.currency]: message.e };

      window.dispatchEvent(new CustomEvent(EventPricePercentageChanged, { detail: { priceChangePercentage24H, coinId }}));
      window.dispatchEvent(new CustomEvent(EventPriceChanged, { detail: { priceBTC, coinId }}));
    }

    super.receive(message)
  }

  async setCoins(coins){
    return this.perform("set_coins", { coins });
  }

  async setCurrency(currency) {
    this.currency = null;
    return this.perform("set_currency", { curr: currency });
  }
}
