import { Controller } from "stimulus";
import { trackEvent } from "../../analytics";
import { SELECT_FAST_PASS_CTA } from "../../mixpanel_config";

export default class extends Controller {
  static targets = ["checkbox", "radio", "submitForm"]

  checkout(e) {
    const radioChecked = this.radioTargets.find(radio => radio.checked);

    if (this.checkboxTarget.checked && radioChecked) {
      this._appendIdToCheckoutUrl(radioChecked.value);
      trackEvent(SELECT_FAST_PASS_CTA, {});
      Modal.show("fastpass_payment_select");
    } else {
      alert("Please accept the Fast Pass Terms or select the request to submit the form.");
    }
  }

  updatePaymentLink(e) {
    const currentUrl = new URL(this.submitFormTarget.dataset.stripeCheckoutPaymentsUrlValue, window.location.origin);
    const newBaseUrl = e.currentTarget.dataset.paymentsUrl;
    const params = currentUrl.searchParams.toString(); // Extract current parameters
    const newUrl = `${newBaseUrl}?${params}`;
    this.submitFormTarget.dataset.stripeCheckoutPaymentsUrlValue = newUrl;
    Modal.hide("fastpass_payment_select");
    Modal.show(e.currentTarget.dataset.modalName);
  }

  _appendIdToCheckoutUrl(value) {
    let url = this.submitFormTarget.dataset.stripeCheckoutPaymentsUrlValue;
    let params = new URLSearchParams({ id: value });
    url += `&${params.toString()}`;
    this.submitFormTarget.dataset.stripeCheckoutPaymentsUrlValue = url
  }
}
