class MemoizedFetcher {
  constructor() {
    this.cache = {};
  }

  async fetchWithCache(url, options = {}, expiry = 300000, onError = null) {
    // Sort the options alphabetically so that the cache key is order agnostic
    options = Object.keys(options)
      .sort()
      .reduce((sortedOptions, key) => {
        sortedOptions[key] = options[key];
        return sortedOptions;
      }, {});
    const cacheKey = JSON.stringify({ url, options });
    const now = Date.now();

    // Check if the response is already cached and not expired
    if (this.cache[cacheKey] && this.cache[cacheKey].expiry > now) {
      return this.cache[cacheKey].data;
    }

    // Fetch the data from remote URL
    try {
      const response = await fetch(url, options);
      if (!response.ok) throw new Error(`HTTP error. Status code: ${response.status}`);

      const data = await response.json();
      this.cache[cacheKey] = { data, expiry: now + expiry }

      return data;
    } catch (e) {
      if (typeof onError !== 'function') return console.error("Fetch failed due to the following error:", e);

      return onError(e);
    }
  }
}

// Return singleton object of MemoizedFetcher
export const memoizedFetcher = new MemoizedFetcher();
