import { Controller } from "stimulus";
import { EventPricePercentageChanged, EventCurrencyChanged } from "../../events";
import { getActiveCurrency } from "../../util/currency";

// Async type definitions:-
const ASYNC_TYPE_URL = {
  coin_price: (ids, currencyCode = getActiveCurrency()) => {
    return `/coins/price_percentage_change?ids=${ids}&vs_currency=${currencyCode}`;
  },
};


// This controller is responsible for executing percentage changes from websocket updates or currency change.
// There is only one instance of this controller per page for performance reasons.
//
// There are two ways a percent change data is displayed and updated; async and non-async.
// The data-json attribute is never overwritten.
//  - async mode only has usd currency in data-json initially
//  - async mode makes an API call to fetch data whenever currency changes
//  - async mode also waits for websocket events (coin 24h price percent change only)
//
//  - non-async mode has all currencies in data-json initially
//  - non-async mode will use data-json attribute whenever currency changes
//  - non-async mode also waits for websocket events (coin 24h price percent change only)
export default class extends Controller {
  static targets = ["percent"];

  coinId24hTargets = {};
  asyncTargets = {};
  nonAsyncTargets = [];

  currencyCode = "usd";

  connect() {
    this.currencyCode = getActiveCurrency();

    this._loadTargets();

    // Listen to events to react to new data and update targets.
    window.addEventListener(EventPricePercentageChanged, this._handleWebsocketEvent.bind(this));
    window.addEventListener(EventCurrencyChanged, this._handleCurrencyChangeEvent.bind(this));
  }

  // Index all targets on page for faster lookups.
  _loadTargets() {
    this.asyncTargets = {};
    this.nonAsyncTargets = [];

    this.percentTargets.forEach(target => {
      let coinId = +target.getAttribute("data-coin-id");
      if (coinId && target.getAttribute("data-24h") === "true") {
        this.coinId24hTargets[coinId] ||= [];
        this.coinId24hTargets[coinId].push(target);
      }

      let asyncType = target.getAttribute("data-async");
      if (asyncType) {
        this.asyncTargets[asyncType] ||= [];
        this.asyncTargets[asyncType].push(target);
      } else {
        this.nonAsyncTargets.push(target);
      }
    });
  }

  _handleWebsocketEvent(e) {
    const coinId = +e.detail?.coinId;
    if (!coinId) {
      return;
    }

    const dataJson = e?.detail?.priceChangePercentage24H;
    const percentChange = dataJson?.[this.currencyCode];
    if (!dataJson || !percentChange) {
      return;
    }

    // Websocket event only contains 24h price percent change data.
    this.coinId24hTargets[coinId]?.forEach(target => this._updatePercentChange(target, percentChange));
  }

  _handleCurrencyChangeEvent(e) {
    this._loadTargets();

    this.currencyCode = e.detail.currencyCode.toLowerCase();

    // Make async targets load from API.
    for (let [asyncType, targets] of Object.entries(this.asyncTargets)) {
      const asyncUrlFunction = ASYNC_TYPE_URL[asyncType];
      if (!asyncUrlFunction || !targets.length) {
        return;
      }

      // Get unique async IDs from targets for this async type.
      const asyncIds = [...new Set(targets.map(x => +x.getAttribute("data-async-id")))];
      const asyncUrl = asyncUrlFunction(asyncIds.sort().join(","), this.currencyCode);

      fetch(asyncUrl)
        .then(response => response.json())
        .then(result => {
          targets.forEach(target => {
            const asyncId = target.getAttribute("data-async-id");
            const asyncAttr = target.getAttribute("data-attr");

            const percentChange = result[asyncId][asyncAttr];
            this._updatePercentChange(target, percentChange);
          });
        });
    }


    // Make non-async targets change data based on available "data-json".
    this.nonAsyncTargets.forEach(target => {
      const jsonData = JSON.parse(target.getAttribute("data-json"));

      const percentChange = jsonData[this.currencyCode];
      this._updatePercentChange(target, percentChange);
    });
  }

  _updatePercentChange(target, percentChange) {
    if (percentChange == null) {
      return;
    }

    const iconTarget = target.getElementsByTagName("i")[0];
    const textTarget = iconTarget ? iconTarget.nextSibling : target;

    // Display new percent change, update color and icons.
    if (percentChange >= 0) {
      target.classList.add("gecko-up");
      target.classList.remove("gecko-down");

      iconTarget?.classList?.add("fa-caret-up");
      iconTarget?.classList?.remove("fa-caret-down");
    } else {
      target.classList.add("gecko-down");
      target.classList.remove("gecko-up");

      iconTarget?.classList?.add("fa-caret-down");
      iconTarget?.classList?.remove("fa-caret-up");
    }



    // Moon Design spec has no negative symbol when arrow is shown.
    if (iconTarget) {
      percentChange = Math.abs(percentChange)
    }

    let percentChangeDisplay = percentChange.toFixed(1);

    // Format with toLocaleString() if less than 1 quadrillion, otherwise use e notation.
    if (target.getAttribute("data-formatted") === "true") {
      if (percentChangeDisplay < 1e15) {
        const locale = document.body.getAttribute("data-locale") || "en";
        percentChangeDisplay = percentChange.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      } else {
        percentChangeDisplay = percentChange.toExponential();
      }
    }

    if (textTarget) {
      textTarget.textContent = `${percentChangeDisplay}%`;
    }
  }
}
