import { createCable } from '@anycable/web'

import CeChannelV2 from "../channels/ce_channel";
import PwChannel from "../channels/pw_channel";

export const initializeAnyCable = () => {
    const cable = createCable();

    PwChannel.setupChannel(cable);
    CeChannelV2.setupChannel(cable);
}
