import { Channel } from "@anycable/web"

export default class CeChannelV2 extends Channel {
  static identifier = 'CeChannelV2'

  static setupChannel(cable) {
    cable.subscribe(new CeChannelV2())
  }

  receive(message) {
    fx.rates = message.rates || message.r; // data.rates to be deprecated

    super.receive(message)
  }
}
